<template>
  <div class="container">
    <div class="content">
      <my-icon fontSize="128" iconName="icon-wangluojiance_wancheng_128_lv"></my-icon>
      <div class='fin_style'>{{$t('login.gxsuccess')}}</div>
      <div class='back_style'>
        <span v-if="$i18n.locale === 'zh-CN'">{{count}} 秒后立即将跳转到登录 </span>
        <span v-else>page Resend in {{count}} s </span>
        <span class='yb_style' @click='clickLogin'>{{$t('login.nowLogin')}}</span>
      </div>
      <Back :type='type'></Back>
    </div>
  </div>
</template>
<script>
  import Back from "@/views/login/back.vue";
  export default {
    components: {
      Back
    },
    data() {
      return {
        timer: null,
        count: 3,
        type:'login',
      }
    },
    mounted() {
      this.goto();
    },
    destroyed() {
      clearInterval(this.timer);
    },
    methods: {
        // 返回倒计时
    goto() {
      if (!that.timer) {
        that.timer = setInterval(() => {
          if (this.count > 1 && this.count <= 10) {
            this.count = this.count - 1;
          } else {
            this.$router.push({
              name: "login",
              params: {
                isClick: true,
              },
            });
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },
      clickLogin() {
      let that = this;
        this.$router.push({
          name: 'login'
        })
      },
    }
  }

</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;

    .content {
      width: 60%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .fin_style {
        font-size: 32px;
        color: #333333;
        margin: 20px 0 10px 0;
      }

      .back_style {
        font-size: 24px;
        color: #999999;
        margin-bottom: 48px;

        .yb_style {
          color: #1AB370;
        }
      }

      .back_length {
        margin: auto;
        width: 76%;
      }
    }
  }

</style>
